import Card from "@mui/joy/Card";
import {CardActions, CardCover, DialogTitle, FormControl, FormLabel, Link, Modal, ModalDialog, Stack,} from "@mui/joy";
import IconButton from "@mui/joy/IconButton";
import AddIcon from "@mui/icons-material/Add";
import FavoriteBorderTwoToneIcon from "@mui/icons-material/FavoriteBorderTwoTone";
import RemoveIcon from '@mui/icons-material/Remove';
import CardContent from "@mui/joy/CardContent";
import EditIcon from '@mui/icons-material/Edit';
import Typography from "@mui/joy/Typography";
import * as React from "react";
import {useState} from "react";
import Box from "@mui/joy/Box";
import Input from "@mui/joy/Input";
import {toast} from "react-toastify";
import {CardEditModal} from "../lib/Utils";
import Button from "@mui/joy/Button";

function CollectionModal({comment, setComment, collectionModalOpen, setCollectionModalOpen, handleAddCollection}) {
    return (<Modal open={collectionModalOpen} onClose={() => setCollectionModalOpen(false)}>
        <ModalDialog>
            <DialogTitle>Add To Collection</DialogTitle>
            <Box>
                <Input
                    id="comment"
                    size="sm"
                    variant="outlined"
                    placeholder="Personal comment…"
                    onChange={(e) => {
                        setComment(e.target.value);
                    }}
                    sx={{
                        alignSelf: 'center',
                        display: {
                            xs: 'none',
                            sm: 'flex',
                        },
                    }}> {comment}</Input>
            </Box>
            <Button onClick={() => {
                setCollectionModalOpen(false);
                handleAddCollection(comment);
            }}>
                Add
            </Button>
        </ModalDialog>
    </Modal>);
}

function ListItem({card, entry, field}) {
    if (card[field].length === 0) {
        return null;
    }
    return (<FormControl>
        <FormLabel>{entry}</FormLabel>
        {
            card[field].map((data) =>
                <Typography color="primary"> {data}</Typography>
            )
        }
    </FormControl>);
}

function BoolItem({card, entry, field}) {
    if (card[field] === null) {
        return null;
    }
    const value = card[field] ? "Yes" : "No";
    return (<FormControl>
        <FormLabel>{entry}</FormLabel>
        <Typography color="primary"> {value} </Typography>
    </FormControl>);
}

function StrItem({card, entry, field}) {
    if (card[field] === null) {
        return null;
    }
    return (<FormControl>
        <FormLabel>{entry}</FormLabel>
        <Typography color="primary"> {card[field]}</Typography>
    </FormControl>);
}

export function CardItem({card, setCards, isAdmin, token, showActions, currentData}) {
    const [modalOpen, setModalOpen] = useState(false);
    const [comment, setComment] = useState('No comment');
    const [collectionModalOpen, setCollectionModalOpen] = useState(false);

    const [displayModalOpen, setDisplayModalOpen] = useState(false);

    function CardDisplayModal() {


        return (<Modal open={displayModalOpen} onClose={() => setDisplayModalOpen(false)}
                       sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <ModalDialog>
                <DialogTitle>Card Information</DialogTitle>
                <Box sx={{overflow: 'auto', width: 500}}>
                    <Stack spacing={2}>
                        <StrItem card={card} entry="Name" field="name"></StrItem>
                        <StrItem card={card} entry="Long Name" field="long_name"></StrItem>
                        <StrItem card={card} entry="Annual Fee" field="annual_fee"></StrItem>
                        <StrItem card={card} entry="Network" field="network"></StrItem>
                        <StrItem card={card} entry="Issuing Bank" field="issuing_bank"></StrItem>
                        <BoolItem card={card} entry="Is Charge Card?" field="charge_card"></BoolItem>
                        <StrItem card={card} entry="Type" field="type"></StrItem>
                        <BoolItem card={card} entry="Has FTF?" field="has_ftf"></BoolItem>
                        <StrItem card={card} entry="Reward Type" field="reward_type"></StrItem>
                        <ListItem card={card} entry="Reward Category" field="reward_category"></ListItem>
                        <ListItem card={card} entry="Protection" field="protection"></ListItem>
                        <ListItem card={card} entry="Annual Perks" field="annual_perks"></ListItem>
                        <ListItem card={card} entry="Elite Status" field="elite_status"></ListItem>
                        <ListItem card={card} entry="Lounge Access" field="lounge_access"></ListItem>
                        <StrItem card={card} entry="Additional User Fee" field="additional_user_fee"></StrItem>
                        <StrItem card={card} entry="Issuing Bank Origin Country"
                                 field="issuing_bank_origin_country"></StrItem>
                        <BoolItem card={card} entry="Metal Card?" field="metal_card"></BoolItem>
                        <StrItem card={card} entry="Cobrand" field="cobrand"></StrItem>
                        <BoolItem card={card} entry="Is Discontinued?" field="is_discontinued"></BoolItem>
                    </Stack>
                </Box>
            </ModalDialog>
        </Modal>);
    }

    function handleRemove() {
        if (token === '') {
            toast('Login required.');
            return;
        }
        if (currentData === 'My Favorites') {
            fetch(process.env.REACT_APP_BACKEND_URL + 'favorites', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    card_id: card.id,
                    operation: 'deletion'
                })
            }).then(r => {
                if (r.ok) {
                    toast('Removed from favorites.');
                    setCards((prev)=>{
                        return prev.filter((item) => {
                            return card.id !== item.id;
                        })
                    });
                } else {
                    toast(`HTTP Error ${r.status}`);
                }
            });
        } else if (currentData === 'My Collection') {
            fetch(process.env.REACT_APP_BACKEND_URL + 'card_collections', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                    id: card.card_collection_id,
                    operation: 'deletion'
                })
            }).then(r => {
                if (r.ok) {
                    toast('Removed from collection');
                    setCards((prev)=>{
                        return prev.filter((item) => {
                            return card.card_collection_id !== item.card_collection_id;
                        })
                    });
                } else {
                    toast(`HTTP Error ${r.status}`);
                }
            });
        }
    }

    function handleAddFavorite() {
        if (token === '') {
            toast('Login Required');
            return;
        }
        fetch(process.env.REACT_APP_BACKEND_URL + 'favorites', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
                card_id: card.id,
                operation: 'add'
            })
        }).then(r => {
            if (r.ok) {
                toast('Added to favorites.');
            } else {
                toast(`HTTP Error ${r.status}`);
            }
        });
    }

    function handleAddCollection(comment) {
        if (token === '') {
            toast('Login Required');
            return;
        }
        fetch(process.env.REACT_APP_BACKEND_URL + 'card_collections', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
                card_id: card.id,
                operation: 'add',
                comment: comment
            })
        }).then(r => {
            if (r.ok) {
                toast('Added to collection.');
            } else {
                toast(`HTTP Error ${r.status}`);
            }
        });
    }

    return (
        <Box>
            <Card variant="outlined" sx={{width: 320, aspectRatio: 1.57}}>
                <CardCover>
                    <img
                        src={card.image}
                        loading="lazy"
                    />
                </CardCover>
                <CardCover
                    sx={{
                        background:
                            'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.2), rgba(0,0,0,0) 300px)',
                    }}
                />
                {
                    currentData === 'All' ?
                        <CardActions sx={{justifyContent: 'space-between', flexDirection: 'row', zIndex: 999}}>
                            <IconButton aria-label="Have / Had this card" onClick={() => setCollectionModalOpen(true)}>
                                <AddIcon
                                    sx={{color: "white"}}/> </IconButton>
                            <IconButton aria-label="Add to Favorite" onClick={handleAddFavorite}>
                                <FavoriteBorderTwoToneIcon sx={{color: "white"}}/>
                            </IconButton>
                        </CardActions> : null
                }
                {
                    currentData === 'My Collection' || currentData === 'My Favorites' ?
                        <CardActions sx={{justifyContent: 'space-between', flexDirection: 'row', zIndex: 999}}>
                            <IconButton aria-label="Remove" onClick={handleRemove}>
                                <RemoveIcon sx={{color: "white"}}/>
                            </IconButton>
                        </CardActions> : null
                }
                <CardContent sx={{justifyContent: 'flex-end'}}>
                    <Typography level="title-md" textColor="#fff">
                        {
                            isAdmin ? <Link
                                underline='none'
                                component='button'
                                color='white'
                                onClick={() => setModalOpen(true)}> {card.name} <EditIcon></EditIcon>
                            </Link> : <Link
                                underline='none'
                                component='button'
                                color='white'
                                overlay
                                onClick={() => setDisplayModalOpen(true)}> {card.name}
                            </Link>
                        }
                    </Typography>
                    {
                        currentData === "My Collection" ? <Typography textColor="#bbb">
                            {card.comment}
                        </Typography> : null
                    }
                </CardContent>
            </Card>
            <CollectionModal comment={comment} setComment={setComment} collectionModalOpen={collectionModalOpen}
                             handleAddCollection={handleAddCollection} setCollectionModalOpen={setCollectionModalOpen}/>
            <CardEditModal modalOpen={modalOpen} setModalOpen={setModalOpen} token={token} initial_card={card}/>
            <CardDisplayModal/>
        </Box>
    );
}