import {Checkbox, FormLabel, List, ListItem, Select, Option, Slider} from "@mui/joy";
import AspectRatio from "@mui/joy/AspectRatio";
import {Done} from "@mui/icons-material";
import * as React from "react";
import {useEffect, useState} from "react";
import Box from "@mui/joy/Box";

function FilterOption({item, includedNetwork, setIncludedNetwork}) {
    const selected = includedNetwork.includes(item);
    return (<ListItem key={item}>
        <AspectRatio
            variant={selected ? 'solid' : 'outlined'}
            color={selected ? 'primary' : 'neutral'}
            ratio={1}
            sx={{width: 20, borderRadius: 20, ml: -0.5, mr: 0.75}}
        >
            <div>{selected && <Done fontSize="md"/>}</div>
        </AspectRatio>
        <Checkbox
            size="sm"
            color="neutral"
            disableIcon
            overlay
            label={item}
            variant="outlined"
            checked={selected}
            onChange={(event) =>
                setIncludedNetwork((prev) => {
                    if (!event.target.checked) {
                        return prev.filter((prev_item) => prev_item !== item)
                    } else {
                        return [...prev.filter((prev_item) => prev_item !== item), item];
                    }
                })
            }
            slotProps={{
                action: {
                    sx: {
                        '&:hover': {
                            bgcolor: 'transparent',
                        },
                    },
                },
            }}
        />
    </ListItem>);
}

export function FilterSidebar({cards, setCardFilteringFunc, isAdmin}) {
    // Network
    const allNetworkOptions = [
        'Visa',
        'Discover',
        'Mastercard',
        'Amex',
    ];
    const [includedNetwork, setIncludedNetwork] = useState([]);

    // Issuing Bank
    const allIssuingBanks = cards.map((item) => {
        if ((isAdmin || item.image.length !== 0) && item.issuing_bank.length !== 0) {
            return item.issuing_bank;
        }
        return null;
    }).filter((elem) => elem !== null);
    const allIssuingBankOptions = [...allIssuingBanks.filter((item, pos) => allIssuingBanks.indexOf(item) === pos), 'All'];
    const [selectedIssuingBank, setSelectedIssuingBank] = useState('All');
    const [noAnnualFee, setNoAnnualFee] = useState(false);
    const [checkAnnualFee, setCheckAnnualFee] = useState(false);
    const [annualFeeRange, setAnnualFeeRange] = useState([1, 300]);
    const [miscFilters, setMiscFilters] = useState(
        {
            noFTF: false,
            hotelCard: false,
            airlineCard: false,
            cashbackCard: false,
            nonUSBank: false,
            restaurantCard: false,
            groceryCard: false,
            gasCard: false,
            bonusForMobileWallet: false,
            metalCard: false
        }
    );
    const filterNames = [
        'No FTF',
        'Hotel Card',
        'Airline Card',
        'Cashback Card',
        'Non US Bank',
        'Restaurant Card',
        'Grocery Card',
        'Gas Card',
        'Bonus for Mobile Wallet',
        'Metal Card'
    ];

    // Recalculate on filter change.
    useEffect(() => {
        setCardFilteringFunc(() => (item) => {
            // Filter based on selected filters
            if (noAnnualFee && item.annual_fee.length !== 0 && parseInt(item.annual_fee) !== 0) {
                return false;
            }
            if (checkAnnualFee && (item.annual_fee.length === 0 || (parseInt(item.annual_fee) < annualFeeRange[0] || parseInt(item.annual_fee) > annualFeeRange[1]))) {
                return false;
            }
            if (selectedIssuingBank !== 'All' && item.issuing_bank !== selectedIssuingBank) {
                return false;
            }
            if (includedNetwork.length > 0 && !includedNetwork.includes(item.network)) {
                return false;
            }
            if (miscFilters.noFTF && item.has_ftf) {
                return false;
            }
            if (miscFilters.hotelCard && !item.reward_type.includes('hotel')) {
                return false;
            }
            if (miscFilters.airlineCard && !item.reward_type.includes('airline')) {
                return false;
            }
            if (miscFilters.cashbackCard && !item.reward_type.includes('cashback')) {
                return false;
            }
            if (miscFilters.nonUSBank && item.issuing_bank_origin_country === 'US') {
                return false;
            }
            if (miscFilters.restaurantCard && !item.reward_category.includes('dining')) {
                return false;
            }
            if (miscFilters.groceryCard && !item.reward_category.includes('grocery')) {
                return false;
            }
            if (miscFilters.gasCard && !item.reward_category.includes('gas')) {
                return false;
            }
            if (miscFilters.metalCard && !item.metal_card) {
                return false;
            }
            if (miscFilters.bonusForMobileWallet && !item.reward_category.includes('mobile wallet')) {
                return false;
            }
            // Add more filter conditions based on your requirements
            return true;
        })
    }, [includedNetwork, selectedIssuingBank, noAnnualFee, checkAnnualFee, annualFeeRange, miscFilters]);

    return (<List
        orientation="vertical"
        size="sm"
        sx={{
            '--List-gap': '12px',
            '--ListItem-radius': '20px',
        }}
    >
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1.2,
        }}>
            <FormLabel>Network</FormLabel>
            <Box>
                {
                    allNetworkOptions.map((item, _) =>
                        <FilterOption item={item} includedNetwork={includedNetwork}
                                      setIncludedNetwork={setIncludedNetwork}/>)
                }
            </Box>
            <FormLabel>Issuing Bank</FormLabel>
            <Select
                slotProps={{
                    listbox: {
                        sx: {
                            maxHeight: '200px',
                        },
                    },
                }}
                defaultValue="All" onChange={(e, new_value) => {
                setSelectedIssuingBank(new_value);
            }}>
                {
                    allIssuingBankOptions.map((item, _) =>
                        <Option value={item}> {item}</Option>)
                }
            </Select>
            <FormLabel>
                Annual Fee
            </FormLabel>
            <Checkbox checked={noAnnualFee} label='No Annual Fee' onChange={(e) => {
                setNoAnnualFee(e.target.checked);
            }}/>
            <Checkbox checked={checkAnnualFee} label='Set Annual Fee Range' onChange={(e) => {
                setCheckAnnualFee(e.target.checked);
            }} disabled={noAnnualFee}/>
            {
                checkAnnualFee ?
                    <Slider getAriaLabel={() => 'Annual Fee Range'}
                            min={1}
                            max={1000}
                            value={annualFeeRange}
                            onChange={(e, new_value) => {
                                setAnnualFeeRange(new_value);
                            }}
                            valueLabelDisplay="auto"
                            disabled={noAnnualFee}></Slider> : null
            }
            <FormLabel>
                Other Filters
            </FormLabel>
            <Box>

                {
                    Object.keys(miscFilters).map((item, index) => {
                        const filterKey = item;
                        const selected = miscFilters[filterKey];
                        return (
                            <ListItem key={item}>
                                <AspectRatio
                                    variant={selected ? 'solid' : 'outlined'}
                                    color={selected ? 'primary' : 'neutral'}
                                    ratio={1}
                                    sx={{width: 20, borderRadius: 20, ml: -0.5, mr: 0.75}}
                                >
                                    <div>{selected && <Done fontSize="md"/>}</div>
                                </AspectRatio>
                                <Checkbox
                                    size="sm"
                                    color="neutral"
                                    disableIcon
                                    overlay
                                    label={filterNames[index]}
                                    variant="outlined"
                                    checked={selected}
                                    onChange={(event) =>
                                        setMiscFilters((prev) => {
                                            if (!event.target.checked) {
                                                return {...prev, [filterKey]: false};
                                            } else {
                                                return {...prev, [filterKey]: true};
                                            }
                                        })
                                    }
                                    slotProps={{
                                        action: {
                                            sx: {
                                                '&:hover': {
                                                    bgcolor: 'transparent',
                                                },
                                            },
                                        },
                                    }}
                                />
                            </ListItem>
                        );
                    })
                }
            </Box>
        </Box>
    </List>);
}