import * as React from 'react';
import {useEffect, useState} from 'react';
import ReactGA from 'react-ga4';

import {Box, ButtonGroup, CardActions, CardCover, CssBaseline, CssVarsProvider} from "@mui/joy";
import Card from "@mui/joy/Card";
import Layouts from "./components/Layouts";
import {Header} from "./components/Header";
import {CardItem} from "./components/CardItem";
import Button from "@mui/joy/Button";
import {FilterSidebar} from "./components/FilterSidebar";
import useMediaQuery from '@mui/material/useMediaQuery';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer, toast} from "react-toastify";
import IconButton from "@mui/joy/IconButton";
import AddIcon from "@mui/icons-material/Add";

import {CardEditModal} from "./lib/Utils";
import Input from "@mui/joy/Input";
import {useSearchParams} from "react-router-dom";

function ShareLink({currentData, setCurrentData, user, setCards}) {
    function createShareLink() {
        fetch(process.env.REACT_APP_BACKEND_URL + 'share_collection', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`
            },
            body: JSON.stringify({
                operation: 'create'
            })
        }).then(
            r => r.json()
        ).then(data => {
            if ('share_hash' in data) {
                setShareHash(data.share_hash);
                toast('Share link created.');
            }
        });
    }

    function invalidateShareLink() {
        fetch(process.env.REACT_APP_BACKEND_URL + 'share_collection', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user.token}`
            },
            body: JSON.stringify({
                operation: 'invalidate'
            })
        }).then(
            r => r.json()
        ).then(data => {
            if (data.ok) {
                setShareHash(null);
                toast('Share link invalidated.');
            }
        });
    }

    function handleCopy() {
        navigator.clipboard.writeText(`https://cardquad.com/?share_hash=${shareHash}`);
        toast('Copied to clipboard.');
    }

    const [shareHash, setShareHash] = useState(null);
    useEffect(() => {
        if (user) {
            fetch(process.env.REACT_APP_BACKEND_URL + 'collection_hash?token=' + user.token).then(
                response => response.json()
            ).then(
                data => {
                    if ('share_hash' in data) {
                        setShareHash(data.share_hash);
                    }
                }
            )
        }
    }, [user]);

    if (currentData === "My Collection") {
        return <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 1.5,
                resize: 'horizontal',
                alignSelf: 'center',
                justifyContent: 'space-between'
            }}
        >
            {
                shareHash ? (
                    <>
                        <Input defaultValue={`https://cardquad.com/?share_hash=${shareHash}`} disabled/>
                        <Button onClick={handleCopy} color='secondary'>Copy Link</Button>
                        <Button onClick={invalidateShareLink}> Stop Sharing</Button>
                    </>
                ) : <Button onClick={createShareLink}> Create Share Link </Button>
            }
        </Box>
            ;
    }
    return null;
}

function MainView({selectedType, setSelectedType, filteredItems, isAdmin, user, currentData, setCards}) {
    const [modalOpen, setModalOpen] = useState(false);

    function handleAddCard() {
        setModalOpen(true);
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            overflow: 'auto',
        }}>
            <ButtonGroup
                buttonFlex={1}
                aria-label="flex button group"
                sx={{
                    p: 2,
                    width: 500,
                    maxWidth: '100%',
                    overflow: 'auto',
                    resize: 'horizontal',
                    alignSelf: 'center'
                }}
            >
                <Button onClick={() => {
                    setSelectedType('personal');
                }}
                        sx={{backgroundColor: selectedType === 'personal' ? '#E3EFFB' : undefined}}
                        color={selectedType === 'personal' ? 'primary' : undefined}> Personal </Button>
                <Button onClick={() => {
                    setSelectedType('business');
                }}
                        sx={{backgroundColor: selectedType === 'business' ? '#E3EFFB' : undefined}}
                        color={selectedType === 'business' ? 'primary' : undefined}> Business </Button>
                <Button onClick={() => {
                    setSelectedType('all');
                }}
                        sx={{backgroundColor: selectedType === 'all' ? '#E3EFFB' : undefined}}
                        color={selectedType === 'all' ? 'primary' : undefined}> All </Button>
            </ButtonGroup>
            <ShareLink currentData={currentData} user={user}></ShareLink>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit, 320px)',
                    justifyContent: 'center',
                    gap: 2,
                }}
            >
                {
                    isAdmin ?
                        <Card variant="outlined"
                              sx={{width: 320, aspectRatio: 1.57, display: 'flex', justifyContent: 'center'}}>
                            <CardCover
                                sx={{
                                    background:
                                        'linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 200px), linear-gradient(to top, rgba(0,0,0,0.2), rgba(0,0,0,0) 300px)',
                                }}
                            />
                            <CardActions sx={{justifyContent: 'center', display: 'flex'}}>
                                <IconButton aria-label="Add New Card" onClick={handleAddCard}> <AddIcon></AddIcon>
                                </IconButton>
                            </CardActions>
                            <CardEditModal modalOpen={modalOpen} setModalOpen={setModalOpen}
                                           token={user ? user.token : ''} initial_card={{}}/>
                        </Card>
                        : null
                }
                {filteredItems.map((card) => {
                        if (card.image !== null || isAdmin) {
                            const key = 'card_collection_id' in card ? `collection_${card.card_collection_id}` : card.id;
                            return <CardItem
                                key={key}
                                card={card}
                                setCards={setCards}
                                isAdmin={isAdmin}
                                token={user ? user.token : ''}
                                showActions={currentData === 'All'}
                                currentData={currentData}/>
                        }
                        return null
                    }
                )}
            </Box>
        </Box>)
        ;
}

function SmallViewLayout({filterSidebarOpen, sidebar, main}) {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            overflow: 'auto',
        }}>
            {filterSidebarOpen && sidebar}
            {main}
        </Box>
    );
}

function AutoLayout({useLargeLayout, filterSidebarOpen, sidebar, main}) {
    return (
        useLargeLayout ?
            <LargeViewLayout filterSidebarOpen={filterSidebarOpen} sidebar={sidebar}
                             main={main}></LargeViewLayout> :
            <SmallViewLayout filterSidebarOpen={filterSidebarOpen} sidebar={sidebar} main={main}></SmallViewLayout>
    )
}

function LargeViewLayout({filterSidebarOpen, sidebar, main}) {
    return (<Box sx={{
        display: 'grid',
        gap: 1,
        gridTemplateRows: 'auto',
        gridTemplateAreas: filterSidebarOpen ? `"sidebar main main main"` : '',
        gridTemplateColumns: filterSidebarOpen ? 'repeat(4, 1fr)' : '1',
    }}
    >
        {filterSidebarOpen && <Box
            sx={{
                gridArea: 'sidebar',
            }}
        >
            {sidebar}
        </Box>
        }
        <Box
            sx={{
                gridArea: filterSidebarOpen ? 'main' : '',
            }}
        >
            {main}
        </Box>
    </Box>);
}

function App() {
    const [searchTerm, setSearchTerm] = useState('');
    const [cards, setCards] = useState([]);
    const [cardFilteringFunc, setCardFilteringFunc] = useState(()=>(_)=>true);
    // Use context provider if the application gets more complicated overtime.
    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [filterSidebarOpen, setFilterSidebarOpen] = useState(true);
    const isLargeView = useMediaQuery('(min-width: 530px)');
    const allTypes = {
        "All": "all",
        "Personal Card": "personal",
        "Business Card": "business",
    };
    const [selectedType, setSelectedType] = useState('all');
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const share_hash = searchParams.get('share_hash');
        if (share_hash) {
            // Fetch collection's cards.
            fetch(process.env.REACT_APP_BACKEND_URL + 'share_collection?share_hash=' + share_hash).then(
                response => response.json()
            ).then(
                data => {
                    if ('cards' in data) {
                        setCards(data.cards);
                        setCurrentData(`${share_hash}'s Collection`);
                    } else if ('message' in data) {
                        toast(data.message);
                    }
                }
            );
        } else {
            setCurrentData('All');
        }
    }, [searchParams]);

    const [currentData, setCurrentData] = useState(null);


    // Don't show filter on mobile on default
    useEffect(() => {
        setFilterSidebarOpen(isLargeView);
    }, []);

    // Re-request data on view data changed.
    // TODO: If all cards are too many, can be optimized into load once and switch here.
    useEffect(() => {
        if (currentData === 'My Collection') {
            fetch(process.env.REACT_APP_BACKEND_URL + 'card_collections?token=' + user.token).then(
                response => response.json()
            ).then(
                data => {
                    setCards(data.cards);
                }
            )
        } else if (currentData === 'My Favorites') {
            fetch(process.env.REACT_APP_BACKEND_URL + 'favorites?token=' + user.token).then(
                response => response.json()
            ).then(
                data => {
                    setCards(data.cards);
                }
            )
        } else if (currentData === 'All') {
            fetch(process.env.REACT_APP_BACKEND_URL + 'cards').then(
                response => response.json()
            ).then(
                data => {
                    setCards(data.cards);
                }
            )
        }
    }, [currentData]);

    // Update role when user changed.
    useEffect(() => {
        if (user !== null) {
            fetch(process.env.REACT_APP_BACKEND_URL + 'role?token=' + user.token).then(
                response => response.json()).then(
                data => {
                    setIsAdmin(data.is_admin)
                }
            )
        } else {
            setIsAdmin(false);
        }
    }, [user]);

    // Initialize ReactGA and send view event.
    useEffect(() => {
        if (process.env.NODE_ENV !== "production") {
            console.log("Non production build, skipping analytics.");
        }
        ReactGA.initialize(process.env.REACT_APP_GA4_MEASUREMENT_ID);
        ReactGA.event({
            hitType: "pageview"
        });
    }, []);

    const filteredItems = cards.filter((item) => {
        // Filter based on selected type
        if (selectedType !== 'all' && item.type !== selectedType) {
            return false;
        }
        // Filter based on search term
        if (searchTerm && !item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
            return false;
        }
        // Filter based on card readiness
        if ((item.image === null || item.image.length === 0) && !isAdmin) {
            return false;
        }
        return cardFilteringFunc(item);
    });

    return (<CssVarsProvider disableTransitionOnChange>
        <CssBaseline/>
        <Layouts.Header>
            <Header searchTerm={searchTerm} setSearchTerm={setSearchTerm} setFilterDrawerOpen={setFilterSidebarOpen}
                    user={user} setUser={setUser} currentData={currentData} setCurrentData={setCurrentData}/>
        </Layouts.Header>
        <Layouts.Main>
            <AutoLayout useLargeLayout={isLargeView} filterSidebarOpen={filterSidebarOpen} main={
                <MainView filteredItems={filteredItems} filterSidebarOpen={filterSidebarOpen}
                          selectedType={selectedType} setSelectedType={setSelectedType} user={user}
                          isAdmin={isAdmin}
                          setCards={setCards}
                          currentData={currentData}/>
            }
                        sidebar={
                            filterSidebarOpen &&
                            <FilterSidebar isAdmin={isAdmin} cards={cards} setCardFilteringFunc={setCardFilteringFunc}></FilterSidebar>
                        }
            ></AutoLayout>
        </Layouts.Main>
        <ToastContainer position="bottom-right"></ToastContainer>
    </CssVarsProvider>);
}

export default App;
