import * as React from "react";
import {useState} from "react";
import {toast} from "react-toastify";
import {DialogTitle, Modal, ModalDialog} from "@mui/joy";
import Box from "@mui/joy/Box";
import {JsonForms} from "@jsonforms/react";
import {vanillaCells, vanillaRenderers} from "@jsonforms/vanilla-renderers";
import Button from "@mui/joy/Button";

const cardSchema = {
    type: "object",
    properties: {
        id: {
            type: "integer"
        },
        name: {
            type: "string"
        },
        long_name: {
            type: "string"
        },
        image: {
            type: "string"
        },
        annual_fee: {
            type: "string"
        },
        network: {
            type: "string"
        },
        issuing_bank: {
            type: "string"
        },
        charge_card: {
            type: "boolean"
        },
        type: {
            type: "string"
        },
        has_ftf: {
            type: "boolean"
        },
        reward_type: {
            type: "string"
        },
        reward_category: {
            type: "array",
            items: {
                type: "string"
            }
        },
        protection: {
            type: "array",
            items: {
                type: "string"
            }
        },
        annual_perks: {
            type: "array",
            items: {
                type: "string"
            }
        },
        elite_status: {
            type: "array",
            items: {
                type: "string"
            }
        },
        lounge_access: {
            type: "array",
            items: {
                type: "string"
            }
        },
        additional_user_fee: {
            type: "string"
        },
        issuing_bank_origin_country: {
            type: "string"
        },
        metal_card: {
            type: "boolean"
        },
        cobrand: {
            type: "string"
        }
    }
};
const cardUISchema = {
    type: "VerticalLayout",
    elements: [
        {
            type: "Control",
            scope: "#/properties/id",
            options: {
                readOnly: true
            }
        },
        {
            type: "Control",
            scope: "#/properties/name"
        },
        {
            type: "Control",
            scope: "#/properties/long_name"
        },
        {
            type: "Control",
            scope: "#/properties/image"
        },
        {
            type: "Control",
            scope: "#/properties/annual_fee"
        },
        {
            type: "Control",
            scope: "#/properties/network"
        },
        {
            type: "Control",
            scope: "#/properties/issuing_bank"
        },
        {
            type: "Control",
            scope: "#/properties/charge_card"
        },
        {
            type: "Control",
            scope: "#/properties/type"
        },
        {
            type: "Control",
            scope: "#/properties/has_ftf"
        },
        {
            type: "Control",
            scope: "#/properties/reward_type"
        },
        {
            type: "Control",
            scope: "#/properties/reward_category"
        },
        {
            type: "Control",
            scope: "#/properties/protection"
        },
        {
            type: "Control",
            scope: "#/properties/annual_perks"
        },
        {
            type: "Control",
            scope: "#/properties/elite_status"
        },
        {
            type: "Control",
            scope: "#/properties/lounge_access"
        },
        {
            type: "Control",
            scope: "#/properties/additional_user_fee"
        },
        {
            type: "Control",
            scope: "#/properties/issuing_bank_origin_country"
        },
        {
            type: "Control",
            scope: "#/properties/metal_card"
        },
        {
            type: "Control",
            scope: "#/properties/cobrand"
        },
    ]
};

export function CardEditModal({modalOpen, setModalOpen, token, initial_card}) {
    const [updatedCard, setUpdatedCard] = useState(initial_card);

    function handleSubmit() {
        fetch(process.env.REACT_APP_BACKEND_URL + 'cards', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(updatedCard)
        }).then(r => {
            if (r.ok) {
                toast('Successfully updated.');
            } else {
                toast(`HTTP Error ${r.status}`);
            }
        });
        setModalOpen(false);
    }

    return (<Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalDialog>
            <DialogTitle>Edit Card Information</DialogTitle>
            <Box sx={{overflow: 'auto'}}>
                <JsonForms
                    data={updatedCard}
                    renderers={vanillaRenderers}
                    schema={cardSchema}
                    uischema={cardUISchema}
                    cells={vanillaCells}
                    onChange={({data, errors}) => setUpdatedCard(data)}
                >
                </JsonForms>
            </Box>
            <Button onClick={handleSubmit}>
                Submit
            </Button>
        </ModalDialog>
    </Modal>);
}